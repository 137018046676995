import enGB from 'date-fns/locale/en-GB';
import { DefaultConfig } from './types';
import { defaultConfig } from './default';

export const defaultCompound: DefaultConfig = {
  ...defaultConfig,
  brand: 'compound-uk',
  currency: 'GBP',
  currencyLocales: 'en-EN',
  country: 'United Kingdom',
  healthCoachCreation: true,
  defaultTimezone: 'Europe/London',
  dateFnsLocale: enGB,
  fulfilmentVendors: ['titan'],
  lgaEnabled: true,
  plugsEnabled: false,
  shopEnabled: true,
  isDigitalSigningEnabled: true,
  isDispensementReuseEnabled: true,
};
